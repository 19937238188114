import React, { FC } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { tablet, useQuery } from 'styles/breakpoints';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import BookImage from './BookImage';
import DownloadIcon from '../../../../assets/images/lastingChange/icons/download_icon.svg';

interface ThankYouSectionProps {
  title: string;
  subtitle: string;
  img: string;
  alt: string;
  ebookUrl: string | null;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 1rem;
  width: 100%;
  gap: 110px;
  min-height: 26.75rem;

  @media ${tablet} {
    padding: 1.5rem 1rem 2.5rem;
    flex-direction: column-reverse;
    min-height: unset;
    gap: 3rem;
  }
`;

const TYTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  gap: 3rem;
  @media ${tablet} {
    align-items: center;
    gap: 2rem;
    width: 100%;
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  @media ${tablet} {
    align-items: center;
  }
`;

const TYTitle = styled(Text)`
  width: 100%;
  max-width: 31.25rem;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.5rem;

  @media ${tablet} {
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.375rem;
  }
`;

const TYText = styled(Text)`
  width: 100%;
  max-width: 418px;
  strong {
    font-weight: 600;
  }
  @media ${tablet} {
    max-width: 303px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 18px;
`;

const DecoratePlusSign = styled(Text)`
  color: #ff875a;

  text-align: center;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
`;

const DownloadButton = styled.a`
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
  color: #fff !important;
  border-radius: 6.25rem;
  background: #ff875a;
  height: 3rem;
  padding: 0.5rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 0.25rem;
  @media ${tablet} {
    width: 100%;
  }
`;

const DownloadIconImg = styled(DownloadIcon)`
  width: 1.5rem;
  height: 1.5rem;
  & path {
    stroke: #fff;
  }
`;

const ThankYouSection: FC<ThankYouSectionProps> = ({
  img,
  subtitle,
  title,
  alt,
  ebookUrl,
}) => {
  const { isTablet } = useQuery();

  const { user } = useSelector((state: AppState) => state);

  const { bookImageString, textColor, userName } = user?.quiz_answers;
  const { ebookCheck, paperBook, subContainer } = user?.selected_plans_options;

  function transformString(input: string): string {
    const parts = input.split('/');
    const lastPart = parts[parts.length - 1];
    const number = lastPart.match(/#(.+)/)?.[1] || '';

    return number;
  }

  const resolvePageTextContent = () => {
    if (ebookCheck && !paperBook) {
      return {
        title: 'Your order has been completed 🎉!',
        downloadButton: 'Download my e-book',
      };
    }
    if (!ebookCheck && paperBook) {
      return {
        title: 'Your physical book is being prepared 🎉! ',
        subtitle: 'It should arrive in 8-16 business days',
      };
    }
    return {
      title: 'Your physical book is being prepared 🎉!',
      subtitle: 'It should arrive in 8-16 business days',
      downloadButton: 'Download my e-book',
    };
  };

  const minimizeBookTitle = userName.length > 15;

  const resolveTopStyleValue = () => {
    let topVal;

    switch (true) {
      case isTablet && ebookCheck && !paperBook:
        topVal = minimizeBookTitle ? '19px' : '106px';
        break;
      case isTablet && !ebookCheck && paperBook:
        topVal = minimizeBookTitle ? '138px' : '139px';
        break;
      case !isTablet && ebookCheck && !paperBook:
        topVal = minimizeBookTitle ? '22px' : '104px';
        break;
      case !isTablet && !ebookCheck && paperBook:
        topVal = minimizeBookTitle ? '107px' : '105px';
        break;
      default:
        topVal = undefined;
        break;
    }

    return topVal;
  };

  const resolveFontSizeValue = () => {
    let fontSize;

    switch (true) {
      case isTablet &&
        ((ebookCheck && !paperBook) || (!ebookCheck && paperBook)):
        fontSize = minimizeBookTitle ? '10px' : '14px';
        break;
      case isTablet && ebookCheck && paperBook:
        fontSize = minimizeBookTitle ? '4px' : '6px';
        break;
      case !isTablet &&
        ((ebookCheck && !paperBook) || (!ebookCheck && paperBook)):
        fontSize = minimizeBookTitle ? '7px' : '14px';
        break;
      case !isTablet && ebookCheck && paperBook:
        fontSize = minimizeBookTitle ? '5.5px' : '8px';
        break;
      default:
        fontSize = undefined;
        break;
    }

    return fontSize;
  };

  return (
    <Container>
      <TYTextContainer>
        <TextContainer>
          <TYTitle
            textAlign={isTablet ? 'center' : 'left'}
            dangerouslySetInnerHTML={{ __html: resolvePageTextContent().title }}
          />
          <TYText
            textAlign={isTablet ? 'center' : 'left'}
            dangerouslySetInnerHTML={{
              __html: resolvePageTextContent().subtitle,
            }}
          />
          {resolvePageTextContent()?.downloadButton && (
            <DownloadButton href={ebookUrl} target="_blank">
              <DownloadIconImg />
              {resolvePageTextContent()?.downloadButton}
            </DownloadButton>
          )}
        </TextContainer>
      </TYTextContainer>
      {ebookCheck && !paperBook && (
        <BookImage
          isBackground
          coverUrl={bookImageString}
          alt={alt}
          textColor={textColor}
          userName={userName}
          isEBook
          width={'170px'}
          top={resolveTopStyleValue()}
          fontSize={resolveFontSizeValue()}
        />
      )}
      {!ebookCheck && paperBook && (
        <BookImage
          isBackground
          coverUrl={`lastingChange/success/books/#${transformString(
            bookImageString,
          )}`}
          alt={alt}
          width={isTablet ? '245px' : '188px'}
          height={isTablet ? '324px' : '249px'}
          textColor={textColor}
          userName={userName}
          top={resolveTopStyleValue()}
          fontSize={resolveFontSizeValue()}
        />
      )}
      {ebookCheck && paperBook && (
        <ImageContainer>
          <BookImage
            coverUrl={`lastingChange/success/books/#${transformString(
              bookImageString,
            )}`}
            alt={alt}
            textColor={textColor}
            userName={userName}
            width={isTablet ? '112px' : '122px'}
            top={isTablet ? '70px' : '75px'}
            fontSize={resolveFontSizeValue()}
          />
          <DecoratePlusSign>+</DecoratePlusSign>
          <BookImage
            coverUrl={bookImageString}
            alt={alt}
            textColor={textColor}
            userName={userName}
            width={isTablet ? '110px' : '120px'}
            top={isTablet ? '70px' : '75px'}
            fontSize={resolveFontSizeValue()}
            isEBook
          />
        </ImageContainer>
      )}
    </Container>
  );
};

export default ThankYouSection;
