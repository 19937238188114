import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { DynamicImage, Text } from 'components';
import AppleStoreButton from './AppleStoreButton';
import GooglePlayButton from './GooglePlayButton';
import AppleStoreButtonWY from './AppleStoreButtonsWY';
import GooglePlayButtonWY from './GooglePlayButtonsWY';
import PlatformSelector from './PlatformSelector';
import { tablet, useQuery } from 'styles/breakpoints';
import Carousel from './Carousel';

export type PlatformType = 'ios' | 'android';

interface HowToSectionProps {
  title: string;
  subtitle?: string;
  ios: {
    tabTitle: string;
    steps: { text: string; image: string }[];
  };
  android: {
    tabTitle: string;
    steps: { text: string; image: string }[];
  };
  bottomText?: string;
  bgColor?: string;
  project?: string;
}

const Container = styled.div<{ bgColor?: string }>`
  width: 100%;
  background-color: ${({ bgColor }) =>
    bgColor ? bgColor : 'rgba(128, 77, 161, 0.08)'};
  display: flex;
  justify-content: center;
  padding: 4rem 1rem;
  @media ${tablet} {
    padding: 2.5rem 1rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 71.25rem;
  width: 100%;
  gap: 2.5rem;
  @media ${tablet} {
    gap: 1.5rem;
  }
`;
const StoresContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;
const StoresAndTabsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;

const Heading = styled(Text)`
  max-width: 51.125rem;
  width: 100%;
  text-align: center;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.5rem;
  @media ${tablet} {
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.375rem;
  }
`;

const Subtitle = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  text-align: center;
`;

const BadgesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding: 0 1rem;
  @media ${tablet} {
    gap: 1rem;
  }
`;

const BelowText = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  text-align: center;
`;

const HowToSection: FC<HowToSectionProps> = ({
  android,
  ios,
  subtitle,
  bottomText,
  title,
  bgColor,
  project = 'LC',
}) => {
  const [platform, setPlatform] = useState<PlatformType>('ios');
  const [slide, setSlide] = useState<number>(0);

  const steps = platform === 'android' ? android?.steps : ios?.steps;

  const { isTablet } = useQuery();

  useEffect(() => {
    setSlide(0);
  }, [isTablet, platform]);

  return (
    <Container bgColor={bgColor}>
      <ContentContainer>
        <StoresAndTabsContainer>
          <Heading>{title}</Heading>
          <StoresContainer>
            {subtitle && (
              <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
            )}

            <BadgesWrapper>
              {project === 'LC' ? (
                <>
                  <AppleStoreButton />
                  <GooglePlayButton />
                </>
              ) : (
                <>
                  <AppleStoreButtonWY />
                  <GooglePlayButtonWY />
                </>
              )}
            </BadgesWrapper>
          </StoresContainer>
        </StoresAndTabsContainer>
        <BelowText>{bottomText}</BelowText>
      </ContentContainer>
    </Container>
  );
};

export default HowToSection;
