import React from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, Text } from 'components';
import BookImage from './BookImage';

const OrderItemContainer = styled.div<{ $hasImg?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 1rem;
  width: 100%;
  padding: ${({ $hasImg }) => ($hasImg ? '1rem 0 1rem 0' : '0 0 1rem 0')};
  @media ${tablet} {
    gap: 0.75rem;
    padding: ${({ $hasImg }) =>
      $hasImg ? '0.5rem 0 0.5rem 0' : '0 0 0.5rem 0'};
  }
`;

const TotalItemContainer = styled(OrderItemContainer)`
  justify-content: end;
  gap: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.light100};

  padding-top: 2rem;
  @media ${tablet} {
    justify-content: space-between;
    padding-top: 0.5rem;
  }
`;

const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
  @media ${tablet} {
    gap: 0.125rem;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
`;

const Subtitle = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  @media ${tablet} {
    font-size: 0.75rem;
    line-height: 1rem;
  }
`;

const ScrollToAppWrapper = styled.div`
  color: #804da1;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 26px */
  letter-spacing: -0.48px;
  text-decoration-line: underline;
  scroll-behavior: smooth;
  @media ${tablet} {
    font-size: 12px;
  }
`;

const UpsellDecoration = styled.div<{ $paddingLeft: string }>`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  font-style: italic;
  padding-left: ${({ $paddingLeft }) => $paddingLeft};
  @media ${tablet} {
    font-size: 0.75rem;
    line-height: 1rem;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  gap: 0.375rem;
  align-items: center;
`;

const FreeLabel = styled(Text)`
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background: #00b57a;
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
  @media ${tablet} {
    font-size: 0.75rem;
    line-height: 1rem;
  }
`;

interface SummaryProductProps {
  title: string;
  subtitle: string;
  img: string;
  price: string;
  currency: string;
  userName: string | undefined;
  textColor: string | undefined;
  isEbook: boolean;
  fontSize: string | undefined;
  ebookMin: boolean | undefined;
  oldPrice?: string;
  hasTrial?: any;
}

const SummaryProduct = ({
  title,
  subtitle,
  img,
  price,
  currency,
  userName,
  isEbook,
  textColor,
  fontSize,
  ebookMin,
  oldPrice,
  hasTrial,
}: SummaryProductProps) => {
  const { isTablet } = useQuery();
  return (
    <OrderItemContainer key={title} $hasImg={!!img}>
      {img && (
        <BookImage
          width={isTablet ? '33px' : '56px'}
          coverUrl={img}
          alt="product"
          isEBook={isEbook}
          userName={userName}
          textColor={textColor}
          fontSize={fontSize}
          ebookMin={ebookMin}
        />
      )}
      <ProductInfoContainer>
        <PriceContainer>
          {img ? (
            <ProductTitle>{title}</ProductTitle>
          ) : (
            <UpsellDecoration $paddingLeft={isTablet ? '2.563rem' : '4.5rem'}>
              {title}
            </UpsellDecoration>
          )}
          <PriceWrapper>
            {!price && oldPrice && !hasTrial && (
              <OldPrice>{currency + oldPrice}</OldPrice>
            )}
            {price && !hasTrial ? (
              <Price>{!price || hasTrial ? 'FREE' : currency + price}</Price>
            ) : (
              <FreeLabel>
                {!price || hasTrial ? 'FREE' : currency + price}
              </FreeLabel>
            )}
          </PriceWrapper>
        </PriceContainer>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </ProductInfoContainer>
    </OrderItemContainer>
  );
};

export default SummaryProduct;

const ProductTitle = styled(Text)`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  @media ${tablet} {
    font-size: 1rem;
    line-height: 1.375rem;
  }
`;

const Price = styled(Text)`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media ${tablet} {
    font-size: 1rem;
    line-height: 1.375rem;
  }
`;

const OldPrice = styled(Price)`
  text-decoration: line-through;
`;
