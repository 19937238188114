import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from 'components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { AppState } from 'state/types';
import SummaryProduct from './SummaryProduct';
import DownloadIcon from '../../../../assets/images/lastingChange/icons/download_icon.svg';
import { getLocalisedProduct } from 'utils/localization';
import { updateManditoryUpgrade } from 'state/user/effects';
import axios from 'axios';
import { config } from 'config';

interface OrderSummaryProps {
  title: string;
  scrollToLC: () => void;
  scrollToWY: () => void;
  ebookUrl: string;
}

const DownloadIconImg = styled(DownloadIcon)`
  width: 1.5rem;
  height: 1.5rem;
`;

const Container = styled.div`
  max-width: 71.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 1rem;
  gap: 2rem;
  @media ${tablet} {
    gap: 1rem;
    padding: 2.5rem 1rem;
  }
`;

const Heading = styled(Text)`
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.6875rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const SummaryContainer = styled.div`
  width: 100%;
  max-width: 59.75rem;
  display: flex;
  flex-direction: column;
`;

const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 1rem;
  width: 100%;
  padding-bottom: 1rem;
  @media ${tablet} {
    padding-bottom: 0.75rem;
    gap: 0.5rem;
  }
`;

const TotalItemContainer = styled(OrderItemContainer)`
  justify-content: end;
  border-top: 1px solid #ebebeb;

  padding-top: 1rem;
  @media ${tablet} {
    justify-content: space-between;
    padding-top: 0.75rem;
  }
`;

const ScrollToAppWrapper = styled.div`
  cursor: pointer;
  color: #ff875a;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
  text-decoration-line: underline;
  scroll-behavior: smooth;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  @media ${tablet} {
    font-size: 12px;
  }
`;

const UpsellDownload = styled.a`
  cursor: pointer;
  color: #ff875a !important;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
  text-decoration-line: underline !important;
  align-items: center;
  display: flex;
  scroll-behavior: smooth;
  gap: 0.5rem;
  @media ${tablet} {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
  }
`;

const UpsellDecoration = styled.div`
  color: rgba(40, 41, 61, 0.6);
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.22px;
`;

const UpsellContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const BundleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const OrderSummary: FC<OrderSummaryProps> = ({
  title,
  scrollToLC,
  scrollToWY,
  ebookUrl,
}) => {
  const { isTablet } = useQuery();

  const {
    selected_plans,
    upsell_products,
    quiz_answers,
    selected_plans_options,
    selected_manditory_upgrades,
  } = useSelector((state: AppState) => state.user);
  const dispatch = useDispatch();
  const { bookImageString, userName, textColor } = quiz_answers;

  function transformString(input: string): string {
    const parts = input.split('/');
    const lastPart = parts[parts.length - 1];
    const number = lastPart.match(/#(.+)/)?.[1] || '';

    return number;
  }
  const selectedProduct = getLocalisedProduct(selected_plans[0]);

  const currency = selectedProduct.currencyId;

  const totalPrice = [
    ...(selected_plans || []),
    ...(upsell_products || []),
  ].reduce((prevValue, product) => {
    if (product.has_trials) {
      const localisedProduct = getLocalisedProduct(product);
      const discountedTrialPrice = localisedProduct?.discountedTrialPrice;

      return prevValue + parseFloat(discountedTrialPrice || '0');
    }

    const productPrice = product.prices.filter(
      priceItem => priceItem.currency_id == currency,
    )[0]?.final_price;
    return prevValue + productPrice;
  }, 0);

  const bundleImageMap = {
    'upsell-nurturing-intellectual-curiosity':
      'lastingChange/upsell/bundle/intellectual.png',
    'upsell-how-to-break-bad-habits-and-boost-your-self-discipline':
      'lastingChange/upsell/bundle/habit.png',
    'upsell-relationships-and-emotional-intelligence':
      'lastingChange/upsell/bundle/emotion.png',
    'upsell-overcoming-addictive-behaviors':
      'lastingChange/upsell/bundle/adictive.png',
  };

  if (upsell_products.length > 0 && selected_manditory_upgrades.length === 0) {
    const BundleProductsMap = {
      'upsell-nurturing-intellectual-curiosity': 'upgrade/04',
      'upsell-how-to-break-bad-habits-and-boost-your-self-discipline':
        'upgrade/05',
      'upsell-relationships-and-emotional-intelligence': 'upgrade/06',
      'upsell-overcoming-addictive-behaviors': 'upgrade/07',
    };

    const bundleProducts = upsell_products
      .map(item => BundleProductsMap[item.key as string])
      .filter(item => item);

    if (bundleProducts.length) {
      dispatch(updateManditoryUpgrade(bundleProducts));
    }
  }

  const resolveBundleProductImage = (key?: string) => {
    switch (key) {
      case 'upsell-nurturing-intellectual-curiosity':
        return 'lastingChange/success/upsells/intellectual.png';
      case 'upsell-how-to-break-bad-habits-and-boost-your-self-discipline':
        return 'lastingChange/success/upsells/habits.png';

      case 'upsell-relationships-and-emotional-intelligence':
        return 'lastingChange/success/upsells/emotional.png';

      case 'upsell-overcoming-addictive-behaviors':
        return 'lastingChange/success/upsells/behaviors.png';
      case 'upsell-self-growth-bundle':
        return bundleImageMap['upsell-overcoming-addictive-behaviors'];
      default:
        null;
    }
  };

  const upsellLinks = {
    'upsell-nurturing-intellectual-curiosity':
      'https://firebasestorage.googleapis.com/v0/b/lasting-change-app-production.appspot.com/o/upsells%2FThe_Intellectual_Curiosity_Guide.pdf?alt=media&token=a1858314-68f5-4d43-b2e9-3822af1b1ca0',
    'upsell-how-to-break-bad-habits-and-boost-your-self-discipline':
      'https://firebasestorage.googleapis.com/v0/b/lasting-change-app-production.appspot.com/o/upsells%2FHow_To_Break_Bad_Habits_And_Boost_Your_Self-Discipline.pdf?alt=media&token=81f23de7-b540-4d9f-81d8-399c367c9148',
    'upsell-relationships-and-emotional-intelligence':
      'https://firebasestorage.googleapis.com/v0/b/lasting-change-app-production.appspot.com/o/upsells%2FRelationships_and_emotional_intellingence.pdf?alt=media&token=13e1c3f1-b7a6-4e81-b101-b9f153339d69',
    'upsell-overcoming-addictive-behaviors':
      'https://firebasestorage.googleapis.com/v0/b/lasting-change-app-production.appspot.com/o/upsells%2FHow_to_overcome_addictive_behaviors.pdf?alt=media&token=52df3009-763d-4bcc-a50e-8c4026e4bfff',
  };

  const upgradeList = [
    'upsell-nurturing-intellectual-curiosity',
    'upsell-how-to-break-bad-habits-and-boost-your-self-discipline',
    'upsell-relationships-and-emotional-intelligence',
    'upsell-overcoming-addictive-behaviors',
  ];

  const BundleImageLinks = {
    'upgrade/04': 'lastingChange/success/bundle/intellectual.png',
    'upgrade/05': 'lastingChange/success/bundle/habits.png',
    'upgrade/06': 'lastingChange/success/bundle/intelligence.png',
    'upgrade/07': 'lastingChange/success/bundle/behaviour.png',
  };

  const bundleLinks = () => {
    if (selected_manditory_upgrades.length) {
      switch (selected_manditory_upgrades[0]) {
        case 'upgrade/04':
          return [
            {
              name: 'How to Break Bad Habits',
              link: 'upsell-how-to-break-bad-habits-and-boost-your-self-discipline',
            },
            {
              name: 'Relationships & Emotional Intelligence',
              link: 'upsell-relationships-and-emotional-intelligence',
            },
            {
              name: 'How to Overcome Addictive Behaviours',
              link: 'upsell-overcoming-addictive-behaviors',
            },
          ];
        case 'upgrade/05':
          return [
            {
              name: 'The Intellectual Curiosity Guide',
              link: 'upsell-nurturing-intellectual-curiosity',
            },
            {
              name: 'Relationships & Emotional Intelligence',
              link: 'upsell-relationships-and-emotional-intelligence',
            },
            {
              name: 'How to Overcome Addictive Behaviours',
              link: 'upsell-overcoming-addictive-behaviors',
            },
          ];
        case 'upgrade/06':
          return [
            {
              name: 'The Intellectual Curiosity Guide',
              link: 'upsell-nurturing-intellectual-curiosity',
            },
            {
              name: 'How to Break Bad Habits',
              link: 'upsell-how-to-break-bad-habits-and-boost-your-self-discipline',
            },
            {
              name: 'How to Overcome Addictive Behaviours',
              link: 'upsell-overcoming-addictive-behaviors',
            },
          ];
        case 'upgrade/07':
          return [
            {
              name: 'The Intellectual Curiosity Guide',
              link: 'upsell-nurturing-intellectual-curiosity',
            },
            {
              name: 'How to Break Bad Habits',
              link: 'upsell-how-to-break-bad-habits-and-boost-your-self-discipline',
            },
            {
              name: 'Relationships & Emotional Intelligence',
              link: 'upsell-relationships-and-emotional-intelligence',
            },
          ];
        default:
          return [];
      }
    } else {
      return [
        {
          name: 'The Intellectual Curiosity Guide',
          link: 'upsell-nurturing-intellectual-curiosity',
        },
        {
          name: 'How to Break Bad Habits',
          link: 'upsell-how-to-break-bad-habits-and-boost-your-self-discipline',
        },
        {
          name: 'Relationships & Emotional Intelligence',
          link: 'upsell-relationships-and-emotional-intelligence',
        },
        {
          name: 'How to Overcome Addictive Behaviours',
          link: 'upsell-overcoming-addictive-behaviors',
        },
      ];
    }
  };

  const resolvePageTextContent = product => {
    const price = product?.prices?.filter(
      priceItem => priceItem.currency_id === currency,
    )[0]?.final_price;

    const trialAmount = product?.has_trials
      ? product.trials.filter(priceItem => priceItem.currency === currency)[0]
          ?.amount
      : null;

    if (
      product?.key.includes('lasting-change_upsell_') &&
      product.key.includes('_sub_trial')
    ) {
      const finalPrice = trialAmount !== null ? trialAmount : price ?? 0;

      return {
        title: 'Lasting Change assistant app',
        subtitle: (
          <ScrollToAppWrapper onClick={scrollToLC}>
            <DownloadIconImg />
            Download here
          </ScrollToAppWrapper>
        ),
        hasImage: true,
        is_upsell: product?.is_upsell,
        productImg: 'success/subscription.png',
        price: finalPrice === 0 ? undefined : finalPrice,
        oldPrice: price,
      };
    }
    if (
      product?.key === 'lasting-change_walking-yoga_upsell_6-month_sub_trial'
    ) {
      const finalPrice = trialAmount !== null ? trialAmount : price ?? 0;

      return {
        title: 'Walking Yoga app',
        subtitle: (
          <ScrollToAppWrapper onClick={scrollToWY}>
            <DownloadIconImg />
            Download here
          </ScrollToAppWrapper>
        ),
        hasImage: true,
        is_upsell: product?.is_upsell,
        productImg: 'success/walking-yoga-xsell.png',
        price: finalPrice === 0 ? undefined : finalPrice,
        oldPrice: (price / 2).toFixed(2),
      };
    }
    if (
      product?.key.includes('lasting-change_walking-yoga_upsell') &&
      product?.key.includes('sub_trial')
    ) {
      const finalPrice = trialAmount !== null ? trialAmount : price ?? 0;

      return {
        title: 'Walking Yoga app',
        subtitle: (
          <ScrollToAppWrapper onClick={scrollToWY}>
            <DownloadIconImg />
            Download here
          </ScrollToAppWrapper>
        ),
        hasImage: true,
        is_upsell: product?.is_upsell,
        productImg: 'success/walking-yoga-xsell.png',
        price: finalPrice === 0 ? undefined : finalPrice,
        oldPrice: price,
      };
    }

    if (product?.key.includes('upsell-app')) {
      return {
        title: 'Lasting Change assistant app',
        subtitle: (
          <ScrollToAppWrapper onClick={scrollToLC}>
            <DownloadIconImg />
            Download here
          </ScrollToAppWrapper>
        ),
        price,
        hasImage: true,
        is_upsell: product?.is_upsell,
        productImg: 'success/subscription.png',
      };
    }
    if (product?.is_subscription && product.is_upsell) {
      return {
        title: product.name,
        price,
        hasImage: false,
        is_upsell: product?.is_upsell,
      };
    }
    if (product?.is_subscription) {
      return {
        title: 'Lasting Change assistant app',
        subtitle: (
          <ScrollToAppWrapper onClick={scrollToLC}>
            <DownloadIconImg />
            Download here
          </ScrollToAppWrapper>
        ),
        price,
        hasImage: product.hasImage,
        is_upsell: product?.is_upsell,
        productImg: 'success/subscription.png',
        hasTrial: product?.has_trials,
      };
    }
    if (product?.type === 'physical' && product.is_upsell) {
      return {
        title: product.name,
        price,
        hasImage: false,
        is_upsell: product?.is_upsell,
      };
    }
    if (product?.type === 'physical') {
      return {
        title: product.name,
        subtitle: 'It should arrive in 8-16 business days',
        price,
        hasImage: product.hasImage,
        is_upsell: product?.is_upsell,
        productImg: `lastingChange/success/books/#${transformString(
          bookImageString,
        )}`,
      };
    }
    if (
      (product.key === 'e-book-free' && product.is_upsell) ||
      (product.key === 'upsell-e-book-express-shipping' && product.is_upsell)
    ) {
      return {
        title: product.name,
        price,
        hasImage: false,
        is_upsell: product?.is_upsell,
      };
    }
    if (
      product.key.includes('lasting-change_initial_e-book_otp') ||
      product.key === 'upsell-e-book-express-shipping'
    ) {
      return {
        title: product.name,
        subtitle: (
          <UpsellDownload href={ebookUrl} target="_blank">
            <DownloadIconImg />
            Download here
          </UpsellDownload>
        ),
        price,
        userName: userName,
        isEbook: true,
        textColor: textColor,
        fontSize: '3',
        hasImage: product.hasImage,
        is_upsell: product?.is_upsell,
        productImg: bookImageString,
      };
    }
    if (upgradeList.includes(product.key)) {
      return {
        title: product.name,
        subtitle: (
          <UpsellDownload href={upsellLinks[product.key]} target="_blank">
            <DownloadIconImg />
            Download here
          </UpsellDownload>
        ),
        price,
        hasImage: product.hasImage,
        is_upsell: product?.is_upsell,
        productImg: resolveBundleProductImage(product.key),
      };
    }
    return {
      title: product.name,
      subtitle: (
        <UpsellContainer>
          {bundleLinks().map((item, index) => (
            <BundleContainer key={index}>
              <DownloadIconImg />
              <UpsellDownload href={upsellLinks[item.link]} target="_blank">
                {item.name}
              </UpsellDownload>
            </BundleContainer>
          ))}
        </UpsellContainer>
      ),
      price,
      hasImage: product.hasImage,
      is_upsell: product?.is_upsell,
      productImg:
        BundleImageLinks[selected_manditory_upgrades[0]] ||
        'lastingChange/success/bundle/default.png',
    };
  };

  const subscriptionsSummary = [];
  const paperBookSummary = [];
  const eBookSummary = [];
  const otherBundles = [];

  const allProducts = [...(selected_plans || []), ...(upsell_products || [])];

  allProducts?.forEach(product => {
    const hasImage = product?.is_upsell ? false : true;

    if (product.is_subscription) {
      subscriptionsSummary.push({
        ...product,
        hasImage,
      });
      return;
    }
    if (product.type === 'physical') {
      paperBookSummary.push({ ...product, hasImage });
      return;
    }
    if (
      product.key.includes('lasting-change_initial_e-book_otp') ||
      product.key === 'upsell-e-book-express-shipping'
    ) {
      eBookSummary.push({ ...product, hasImage });
      return;
    } else {
      otherBundles.push({ ...product, hasImage: true });
      return;
    }
  });

  const groupedProductsArray = [
    ...(subscriptionsSummary || []),
    ...(paperBookSummary || []),
    ...(eBookSummary || []),
    ...(otherBundles || []),
  ];

  return (
    <Container>
      <Heading>{title}</Heading>
      <SummaryContainer>
        {groupedProductsArray.map(item => {
          const {
            title,
            subtitle,
            price,
            hasImage,
            productImg,
            userName,
            isEbook,
            fontSize,
            oldPrice,
            hasTrial,
          } = resolvePageTextContent(item);

          return (
            <SummaryProduct
              key={title}
              title={title}
              subtitle={subtitle}
              price={price}
              textColor={textColor}
              isEbook={isEbook || false}
              ebookMin={true}
              fontSize={fontSize}
              currency={selectedProduct?.currency}
              img={hasImage ? productImg : null}
              oldPrice={oldPrice}
              hasTrial={hasTrial}
            />
          );
        })}

        <TotalItemContainer>
          <PriceText>Total:</PriceText>
          <PriceText>
            {selectedProduct?.currency}
            {Math.round(Number(totalPrice) * 100) / 100}
          </PriceText>
        </TotalItemContainer>
      </SummaryContainer>
    </Container>
  );
};

export default OrderSummary;

const PriceText = styled(Text)`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  font-weight: 700;
  @media ${tablet} {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`;
